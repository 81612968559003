var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('img',{ref:"img_src",style:({
      'display': 'block',
      'width': '100%',
      'height': 'auto'
    }),attrs:{"src":_vm.source}}),_c('div',{style:({
        'margin-top': '.5rem',
        'display': 'flex',
        'justify-content': 'center'
    })},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.disable),expression:"disable"}],on:{"click":function($event){_vm.$emit('cut', _vm.emitVal())}}},[_vm._t("cutter")],2)]),_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"ctx"})])}
var staticRenderFns = []

export { render, staticRenderFns }