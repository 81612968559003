<template>
    <aside class="px-3 has-text-light">

        <div class="columns mb-0"
        style="margin-top: 16px">
        <div class="column is-flex is-align-items-center is-flex-direction-column">

            <figure class="image is-64x64 mb-1 is-clickable"
            @click="sidebar('Profile')">
            
            <!-- loading -->
            <b-skeleton
            circle 
            width="64px" 
            height="64px"
            animated
            v-if="$root.profile.thurnail === null">

            </b-skeleton>

            <!-- loaded -->
            <avatar
            v-else-if="$root.profile.thurnail === false"
            :size="64"
            :username="$root.user.name"
            :initials="$root.user.name.charAt(0) + $root.user.lastname.charAt(0)"
            color="#f5f5f5"
            >
            </avatar>

            <img :src="this.$root.profile.thurnail"
            class="is-rounded avatar"
            v-else
            >

            </figure>

            <!-- loaded -->
            <p class="is-size-7 my-2">
            

            <span class="has-text-weight-bold has-text-warning">
                {{ $root.user.role_name }}
            </span>

            </p>

            <div class="sidebar-divider mb-2"></div>

        </div>
        </div>

        <div class="columns">
        <div class="column is-flex is-align-items-center is-flex-direction-column">

            <ul class="sidebar-list">

            <li @click="sidebar('Dashboard')">
                <router-link :to="{ name: 'Dashboard' }">
                <b-icon
                icon="tachometer-alt"></b-icon>
                    Dashboard
                </router-link>
            </li>

            <li @click="sidebar('Transactions')">
                <router-link :to="{ name: 'Transactions' }">
                <b-icon icon="exchange-alt"></b-icon>
                    Transacciones
                </router-link>
            </li>
            <li @click="sidebar('NewTransaction')">
                <router-link :to="{ name: 'NewTransaction' }">
                <b-icon icon="plus"></b-icon>
                    Nueva trasacción
                </router-link>
            </li>
            <li @click="sidebar('Users')">
                <router-link :to="{ name: 'Users' }">
                <b-icon icon="users"></b-icon>
                    Usuarios
                </router-link>
            </li>
            <li @click="sidebar('Admins')">
                <router-link :to="{ name: 'Admins' }">
                    <b-icon icon="user-cog"></b-icon>
                    Administradores
                </router-link>
            </li>
            </ul>

            <div class="sidebar-divider mb-4"></div>

                <b-collapse v-model="collapse"
                animation="slide"
                style="width: 90%"
                class="max-auto">

                    <template #trigger>
                        <ul class="sidebar-dropdown-list">
                            <li role="button"
                            :class="{'active': collapse}">
                                Administración
                                <span class="fas"
                                style="margin-left: .125rem"
                                :class="collapse ? 'fa-caret-up' : 'fa-caret-down'"
                                >
                                </span>
                            </li>
                        </ul>
                    </template>

                    <ul class="sidebar-list is-size-7 mx-auto px-2 mb-4"
                    style="max-width: 90%; border-left: 1px solid #4f4f4f"
                    >
                        <li @click="sidebar('Accounts')">
                            <router-link :to="{ name: 'Accounts' }">
                            <b-icon icon="piggy-bank"></b-icon>
                                Nuestras cuentas
                            </router-link>
                        </li>

                        <li @click="sidebar('Rate')"
                        v-if="$root.isAdminRole">
                            <router-link :to="{ name: 'Rate' }">
                            <b-icon icon="euro-sign"></b-icon>
                                Tasas
                            </router-link>
                        </li>

                        <li @click="sidebar('Report', { year: date.getFullYear() })"
                        v-if="$root.isAdminRole">
                            <router-link :to="{ name: 'Report', params: { year: date.getFullYear() } }">
                            <b-icon icon="chart-bar"></b-icon>
                                Reporte personalizado
                            </router-link>
                        </li>

                        <li @click="sidebar('Invoices')">
                            <router-link :to="{ name: 'Invoices' }">
                            <b-icon icon="file-invoice-dollar"></b-icon>
                                Facturas
                            </router-link>
                        </li>

                        <li @click="sidebar('PaymaticoEntries')"
                        v-if="$root.isAdminRole">
                            <router-link :to="{ name: 'PaymaticoEntries' }">
                            <b-icon icon="money-check-alt"></b-icon>
                                Paymatico
                            </router-link>
                        </li>
                        <li @click="sidebar('PaVenezuela')"
                            v-if="$root.isAdminRole">
                            <router-link :to="{ name: 'PaVenezuela' }">
                                <b-icon icon="money-check-alt"></b-icon>
                                PaVenezuela
                            </router-link>
                        </li>
                    </ul>

                </b-collapse>

            <div class="sidebar-divider mb-4"></div>

            <ul class="sidebar-list">

            <li @click="$root.logout()">
                <a>
                <b-icon icon="sign-out-alt"></b-icon>
                    Cerrar sesión
                </a>
            </li>

            </ul>             

        </div>
        </div>
        
    </aside>
</template>

<script>
import Avatar from 'vue-avatar'
export default {
  data () {
    return {
        collapse: false,
        date: new Date()
    }
  },
  methods: {
      sidebar(route, params){
        
        this.$parent.sidebar();
        this.$router.push({ name: route, params: params === undefined || params === null ? {} : params })
        .catch(()=>{});

      }
  },
    name: 'SideBar',
    components: {
        Avatar
    }
}
</script>

<style lang="scss">
    .avatar{
        border: 3px solid hsl(55, 90%, 76%);
    }
    aside{
        max-height: 100vh;
    }
</style>