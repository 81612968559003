<template>
<div class="container mt-5 pb-5">

    <div class="columns">
        <div class="column">
            <h1 class="title is-4 has-text-primary-dark ml-4 mb-5 px-4">
                Cajas
            </h1>
        </div>
    </div>

    <div class="columns is-mobile mb-5">
        <div class="column is-10 is-offset-1 has-shadow is-rounded has-background-white">
            <div class="is-flex px-4 is-justify-content-space-between is-flex-direction-row">

                <div class="is-flex is-flex-direction-row is-justify-content-start">
                    <span class="is-inline-block mr-2"
                          :class="{ 'is-clickable': view_type !== 'mosaic' }"
                          tabindex="0"
                          title="Ver como mosaicos"
                          @click="view_type = 'mosaic'"
                    >
                        <b-icon icon="th"
                                size="is-medium"
                                :type="view_type === 'mosaic' ? 'is-primary-light' : 'is-primary'"
                                custom-class="transition"
                        />
                    </span>

                    <span class="is-inline-block mr-2"
                          :class="{ 'is-clickable': view_type !== 'table' }"
                          tabindex="0"
                          title="Ver como tabla"
                          @click="view_type = 'table'"
                    >
                        <b-icon icon="th-list"
                                size="is-medium"
                                :type="view_type === 'table' ? 'is-primary-light' : 'is-primary'"
                                custom-class="transition"
                        />
                    </span>

                    <span class="is-inline-block mr-2"
                          :class="{ 'is-clickable': isClosable }"
                          tabindex="0"
                          title="Cerrar"
                          @click="callCloseCheckoutEvent"
                    >
                        <b-icon icon="box"
                                size="is-medium"
                                :type="isClosable ? 'is-danger' : 'is-danger-light'"
                                custom-class="transition"
                        />
                    </span>
                </div>

                <div class="is-flex is-justify-content-flex-end is-flex-direction-row">
                    <b-button type="is-link is-light"
                              size="is-small"
                              rounded
                              icon-right="search"
                              class="mr-2"
                              @click="modal.search = true"
                    />

                    <b-button label="Crear caja"
                              icon-right="plus"
                              type="is-success"
                              size="is-small"
                              rounded
                              @click="modal.store = true"
                    />
                </div>

            </div>
        </div>
    </div>

    <router-view
        :view-type="view_type"
        ref="sub_view"
        @closable-input="isClosable = $event"
        @rate-input="hasRate = $event"
        @loaded-checkouts="checkouts = $event"
    />

    <b-modal scroll="clip"
             :width="540"
             v-model="modal.store"
    >
        <div class="box mb-0 py-5">
            <h3 class="subtitle has-text-primary-dark">
                <b-icon icon="cash-register"
                        type="is-primary-dark"
                        class="mr-2"
                />
                Crear Una Nueva Caja
            </h3>

            <b-steps size="is-small"
                     :has-navigation="false"
                     type="is-info"
                     v-model="store_step"
            >

                <b-step-item label="Nombre"
                             icon="stream"
                >
                    <div class="columns mt-4 mb-0">
                        <div class="column">

                            <form>
                                <b-field label="Nombre"
                                         label-position="on-border"
                                >
                                    <b-input type="text"
                                             placeholder="Nombre único"
                                             rounded
                                             icon="tag"
                                             v-model="checkout_store.name"
                                    />
                                </b-field>

                                <b-field class="is-justify-content-flex-end is-flex is-flex-direction-row"
                                >
                                    <b-button label="Siguiente"
                                              icon-right="chevron-right"
                                              type="is-info"
                                              rounded
                                              :disabled="checkout_store.name === null || checkout_store.name.length < 3"
                                              @click="store_step = 1"
                                    />
                                </b-field>
                            </form>

                        </div>
                    </div>
                </b-step-item>

                <b-step-item label="Cuentas"
                             icon="university"
                >
                    <div class="columns">
                        <div class="column mt-4 mb-0">

                            <form>
                                <b-field label="Cuentas"
                                         label-position="on-border"
                                >
                                    <b-autocomplete :data="filterAccounts"
                                                    placeholder="Cuentas a agregar..."
                                                    icon="plus"
                                                    :max-height="130"
                                                    @select="(item) => { checkout_store.accounts.push(item) }"
                                                    @typing="(v) => account_autocomplete = v"
                                    >
                                        <template slot-scope="props">
                                            <div class="py-2">
                                                <p class="mb-2 pl-1">
                                                    <b-tag type="is-dark"
                                                           class="mr-2"
                                                    >
                                                        {{ props.option.id }}
                                                    </b-tag>
                                                    <span class="is-size-5">
                                                        {{ props.option.bank }}
                                                    </span>
                                                </p>
                                                <p class="is-size-7 has-text-grey pl-3">
                                                    {{ props.option.name }} {{ props.option.lastname }}
                                                </p>
                                            </div>
                                        </template>
                                    </b-autocomplete>
                                </b-field>

                                <section class="p-5"
                                         v-if="checkout_store.accounts.length > 0"
                                >
                                    <div class="is-flex is-flex-wrap-wrap is-flex-direction-row is-justify-content-center">
                                        <b-taglist v-for="(item, index) in checkout_store.accounts"
                                                   :key="`account_to_add_${index}`"
                                                   attached
                                                   class="mr-2 mb-2"
                                        >
                                            <b-tag type="is-dark"
                                                   size="is-medium"
                                            >
                                                {{ item.id }}
                                            </b-tag>
                                            <b-tag type="is-primary"
                                                   size="is-medium"
                                            >
                                                {{ item.bank }}
                                            </b-tag>
                                            <b-tag type="is-danger"
                                                   size="is-medium"
                                                   class="is-clickable"
                                                   @click="removeAccountAggregated(item.id)"
                                            >
                                                <b-icon icon="trash"
                                                />
                                            </b-tag>
                                        </b-taglist>
                                    </div>
                                </section>

                                <p class="p-5 has-text-centered has-text-grey is-italic"
                                   v-else
                                >
                                    No hay cuentas agregadas...
                                </p>

                                <b-field class="is-justify-content-flex-end is-flex is-flex-direction-row"
                                >
                                    <b-button label="Siguiente"
                                              icon-right="chevron-right"
                                              type="is-info"
                                              rounded
                                              :disabled="checkout_store.accounts.length === 0"
                                              @click="store_step = 2"
                                    />
                                </b-field>
                            </form>

                        </div>
                    </div>
                </b-step-item>

                <b-step-item label="Permisos"
                             icon="key"
                >
                    <div class="columns">
                        <div class="column">

                            <div class="pt-4 pb-2">
                                <div class="table-container">
                                    <table class="table is-fullwidth">

                                        <thead>
                                            <tr>
                                                <th class="has-text-centered">Administrador</th>
                                                <th class="has-text-centered">Permisos</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="admin in admins"
                                                :key="`admin_to_add_checkout_${admin.id}`"
                                            >
                                                <td>
                                                    <p class="mb-2">
                                                        <b-tag type="is-dark"
                                                               size="is-medium"
                                                               class="mr-2"
                                                        >
                                                            {{ admin.id }}
                                                        </b-tag>
                                                        <span>
                                                            {{ admin.name }} {{ admin.lastname }}
                                                        </span>
                                                    </p>
                                                    <p class="ml-3 has-text-grey is-size-7">
                                                        {{ admin.role_name }}
                                                    </p>
                                                </td>
                                                <td>
                                                    <b-field style="margin-bottom: .5rem !important;">
                                                        <b-checkbox type="is-success"
                                                                    @input="addOrChangePermission('indexing', admin.id, $event)"
                                                        >
                                                            Ver
                                                            <b-icon icon="eye"
                                                                    type="is-link"
                                                            />
                                                        </b-checkbox>
                                                    </b-field>
                                                    <b-field>
                                                        <b-checkbox type="is-success"
                                                                    @input="addOrChangePermission('closing', admin.id, $event)"
                                                        >
                                                            Cerrar
                                                            <b-icon icon="box"
                                                                    type="is-danger"
                                                            />
                                                        </b-checkbox>
                                                    </b-field>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>

                                <b-field class="is-justify-content-flex-end is-flex is-flex-direction-row"
                                >
                                    <b-button label="Crear Caja"
                                              icon-right="plus"
                                              type="is-success"
                                              rounded
                                              :loading="!loaded.store"
                                              @click="storeNewCheckout"
                                    />
                                </b-field>
                            </div>

                        </div>
                    </div>
                </b-step-item>

                <b-step-item label="Saldos"
                             icon="balance-scale"
                >
                    <div class="columns">
                        <div class="column">

                            <p class="has-text-centered pb-4 mb-4  px-4 mt-2">
                                Establece los saldos iniciales para las cuentas pertenecientes a esta nueva caja.
                            </p>

                            <UpdateInitialBalance v-if="checkout_store.latest_stored !== null"
                                                  :checkout-id="checkout_store.latest_stored.id"
                                                  :accounts="checkout_store.latest_stored.accounts"
                            />

                        </div>
                    </div>
                </b-step-item>

            </b-steps>
        </div>
    </b-modal>

    <b-modal scroll="clip"
             :width="520"
             v-model="modal.close"
    >
        <div class="box mb-0">
            <h3 class="subtitle ml-3">
                Tasas Referenciales
            </h3>

            <form class="px-4">

                <b-field label="USDT a VES"
                         position="is-centered"
                >
                    <b-numberinput step="0.01"
                                   controls-position="compact"
                                   controls-alignment="left"
                                   placeholder="Tasa en Bolívares"
                                   v-model="rates_close.ves"
                    />
                    <p class="control">
                        <span class="button is-static">VES</span>
                    </p>
                </b-field>

                <div class="buttons is-right">
                    <b-button label="Cerrar"
                              type="is-danger"
                              rounded
                              icon-right="box"
                              @click="closeCheckouts"
                    />
                </div>

            </form>
        </div>
    </b-modal>

    <b-modal scroll="clip"
             v-model="modal.search"
             :width="640"
    >
        <div class="box mb-0"
             style="margin-left: 2rem; margin-right: 2rem; padding-left: 2.25rem; padding-right: 2.25rem;"
        >

            <div class="columns">
                <div class="column is-12">
                    <h3 class="subtitle has-text-primary-dark has-text-weight-light">
                        <b-icon icon="search"
                                type="is-primary-dark"
                                class="mr-2"
                        />
                        Filtrar Cierres
                    </h3>
                </div>
            </div>

            <div class="columns">
                <div class="column is-6-tablet is-offset-6-tablet">
                    <b-field label="Caja"
                             label-position="on-border"
                    >
                        <b-select placeholder="Seleccione una caja"
                                  expanded
                                  rounded
                                  icon="cash-register"
                                  v-model="search.selected"
                                  @input="searchCheckout"
                        >
                            <option v-for="checkout in checkouts"
                                    :key="`to-search-checkout-${checkout.id}`"
                                    class="is-capitalized"
                                    :value="checkout.id"
                            >
                                {{ checkout.name }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
            </div>

            <div class="columns p-4">
                <div class="column"
                     v-if="this.loaded.search === false"
                >
                    <div class="columns is-mobile is-flex-wrap-wrap">
                        <div class="column is-4"
                             v-for="index in 9"
                             :key="`search-skeleton-${index}`"
                        >
                            <b-skeleton animated
                                        :height="48"
                            />
                        </div>
                    </div>
                </div>

                <div class="column"
                     v-else-if="this.loaded.search === true && this.search.data === null"
                >
                    <p class="p-4 has-text-centered has-text-grey is-size-5">
                        Seleccione una caja para mostrar en calendario.
                    </p>
                </div>

                <div class="column"
                     v-else
                >
                    <Calendar :attributes="calendarData"
                              locale="es"
                              is-expanded
                              :masks="{ weekdays: 'WWW', dayPopover: 'DD/MM/YYYY' }"
                    >
                        <template #day-popover="{ attributes }">
                            <p class="has-text-light mb-2 mx-3">
                                <span class="has-text-success">
                                    {{ attributes[0].customData.opened_at.format('h:mm a') }}
                                </span>
                                →
                                <span v-if="attributes[0].customData.closed_at !== null"
                                      class="has-text-danger"
                                >
                                    {{ attributes[0].customData.closed_at.format('h:mm a') }}
                                </span>
                                <span v-else>
                                    Pendiente
                                </span>
                            </p>

                            <div class="p-2">
                                <b-dropdown :mobile-modal="false"
                                            expanded
                                            scrollable
                                            position="is-top-left"
                                >
                                    <template #trigger>
                                        <b-button type="is-info is-light"
                                                  size="is-small"
                                                  icon-left="download"
                                                  icon-right="chevron-down"
                                                  label="Exportar"
                                                  expanded
                                        />
                                    </template>

                                    <b-dropdown-item @click="exportOperation(attributes[0].customData.checkout_id, 'unit', attributes[0].customData.offset)"
                                    >
                                        <b-icon class="mr-1"
                                                icon="dot-circle"
                                        />
                                        Unitarias
                                    </b-dropdown-item>

                                    <b-dropdown-item @click="exportOperation(attributes[0].customData.checkout_id, 'lineal', attributes[0].customData.offset)"
                                    >
                                        <b-icon class="mr-1"
                                                icon="exchange-alt"
                                        />
                                        Lineal
                                    </b-dropdown-item>

                                    <template v-if="search.data.has_all_currencies">
                                        <b-dropdown-item separator
                                        />

                                        <b-dropdown-item @click="exportProfit(attributes[0].customData.checkout_id, attributes[0].customData.week_offset, 'ves')"
                                        >
                                            <b-icon class="mr-1"
                                                    icon="money-bill"
                                            />
                                            Profit VES
                                        </b-dropdown-item>

                                        <b-dropdown-item @click="exportProfit(attributes[0].customData.checkout_id, attributes[0].customData.week_offset, 'usd')"
                                        >
                                            <b-icon class="mr-1"
                                                    icon="dollar-sign"
                                            />
                                            Profit USD
                                        </b-dropdown-item>

                                        <b-dropdown-item @click="exportProfit(attributes[0].customData.checkout_id, attributes[0].customData.week_offset, 'general')"
                                        >
                                            <b-icon class="mr-1"
                                                    icon="balance-scale-right"
                                            />
                                            Utilidad
                                        </b-dropdown-item>
                                    </template>

                                    <b-dropdown-item separator
                                    />

                                    <b-dropdown-item @click="exportAllAccounts(attributes[0].customData.checkout_operation_id, attributes[0].customData.checkout_id)"
                                    >
                                        <b-icon class="mr-1"
                                                icon="piggy-bank"
                                        />
                                        <i class="is-size-7">Todas las cuentas</i>
                                    </b-dropdown-item>

                                    <b-dropdown-item v-for="account in search.data.accounts"
                                                     :key="`search-account-export-${account.id}`"
                                                     @click="exportAccountOperation(account.id, attributes[0].customData.checkout_id, attributes[0].customData.checkout_operation_id)"
                                    >
                                        <b-icon class="mr-1"
                                                icon="piggy-bank"
                                        />
                                        ({{ account.id }}) {{ account.bank }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </template>
                    </Calendar>
                </div>
            </div>

        </div>
    </b-modal>

</div>
</template>

<script>
import UpdateInitialBalance from "@/components/Checkout/UpdateInitialBalance.vue";
import Calendar from "v-calendar/lib/components/calendar.umd";

export default {
    components: {
        UpdateInitialBalance,
        Calendar
    },
    data() {
        return {
            view_type: 'mosaic',
            accounts: [],
            admins: [],
            checkouts: [],
            search: {
                selected: null,
                data: null
            },
            loaded: {
                accounts: false,
                admins: false,
                store: true,
                search: true,
                exporting: true
            },
            modal: {
                store: false,
                close: false,
                search: false
            },
            checkout_store: {
                name: null,
                accounts: [],
                admins: [],
                latest_stored: null
            },
            rates_close: {
                eur: null,
                usd: null,
                ves: null
            },
            store_step: 0,
            account_autocomplete: '',
            isClosable: false,
            hasRate: false
        }
    },
    computed: {
        filterAccounts() {
            return this.accounts.filter(v => {
                return (v.bank.toLowerCase().includes(this.account_autocomplete.toLowerCase()) || this.account_autocomplete == v.id) && this.checkout_store.accounts.findIndex(i => i.id === v.id) === -1
            })
        },
        calendarData() {
            if (this.search.data !== null && Array.isArray(this.search.data.operations)) {
                return this.search.data
                    .operations
                    .map(operation => {
                        let start = operation.opened_at !== null ?
                            this.$moment(operation.opened_at)._d :
                            null

                        let end = operation.closed_at !== null ?
                            this.$moment(operation.closed_at)._d :
                            null

                        let opened_at = operation.opened_at !== null ?
                            this.$moment(operation.opened_at) :
                            null

                        let closed_at = operation.closed_at !== null ?
                            this.$moment(operation.closed_at) :
                            null

                        return {
                            key: operation.id,
                            dates: {start, end},
                            highlight: 'red',
                            customData: {
                                opened_at,
                                closed_at,
                                checkout_operation_id: operation.id,
                                checkout_id: operation.checkout_id,
                                offset: operation.offset,
                                week_offset: this.$moment().week() - opened_at.week()
                            },
                            popover: {
                                isInteractive: true,
                                visibility: 'click'
                            }
                        }
                    })
            }

            return []
        }
    },
    watch: {
        modal: {
            handler(value) {
                if (value.store === true && this.accounts.length === 0) {
                    this.getAllAccounts()
                }
                if (value.store === true && this.admins.length === 0) {
                    this.getAllAdmins()
                }
            },
            deep: true
        }
    },
    methods: {
        getAllAccounts() {
            this.loaded.accounts = false

            this.$http.get(`api/account/index`, {
                params: {
                    trashed: 'on',
                    type: 'any'
                },
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                }
            })
                .then(res => {
                    this.accounts = res.data
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.accounts = true
                })
        },
        getAllAdmins() {
            this.loaded.admins = false

            this.$http.get(`api/admin/index`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                }
            })
                .then(res => {
                    this.admins = res.data
                        .filter(admin => admin.role_name !== 'Administrador')
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.admins = true
                })
        },
        removeAccountAggregated(id) {
            let index = this.checkout_store.accounts.findIndex(v => id === v.id)

            this.checkout_store.accounts.splice(index, 1)
        },
        addOrChangePermission(type, admin_id, value) {
            let index = this.checkout_store.admins.findIndex(admin => admin.id === admin_id)

            if (index === -1) {
                this.checkout_store.admins.push({
                    id: admin_id,
                    indexing: type === 'indexing' ? value : false,
                    closing: type === 'closing' ? value : false
                })
            }
            else {
                this.checkout_store.admins[index][type] = value
            }

            (this.checkout_store.admins).forEach((v, i) => {
                if (v.indexing === false && v.closing === false) {
                    this.checkout_store.admins.splice(i, 1)
                }
            })
        },
        storeNewCheckout() {
            this.loaded.store = false

            let name = this.checkout_store.name
            let admins = this.checkout_store.admins
            let accounts = this.checkout_store
                .accounts
                .map(item => item.id)

            this.$http({
                url: 'api/checkout',
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                data: {
                    name,
                    admins,
                    accounts
                }
            })
                .then(res => {
                    this.checkout_store.latest_stored = res.data
                    this.store_step = 3

                    if (this.$refs.sub_view['getCheckouts'] !== undefined && typeof this.$refs.sub_view['getCheckouts'] === 'function') {
                        this.$refs.sub_view['getCheckouts']()
                    }
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.store = true
                })
        },
        callCloseCheckoutEvent() {
            setTimeout(() => {
                this.rates_close.usd = 1
                this.rates_close.eur = 1

                if (!this.hasRate) {
                    this.rates_close.ves = 10
                    this.closeCheckouts()
                }
                else {
                    this.modal.close = true
                }
            }, 100)
        },
        closeCheckouts() {
            if (this.isClosable && this.$refs.sub_view['closedSelectCheckout'] !== undefined && typeof this.$refs.sub_view['closedSelectCheckout'] === 'function') {
                this.$refs.sub_view['closedSelectCheckout'](this.rates_close)
            }

            this.modal.close = false
        },
        searchCheckout() {
            this.loaded.search = false

            this.$http.get(`api/checkout/search/${this.search.selected}`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                }
            })
                .then(res => {
                    this.search.data = {
                        ...res.data,
                        operations: res.data
                            .operations
                            .map((operation, offset) => ({
                                ...operation,
                                offset
                            }))
                    }
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.search = true
                })
        },
        exportOperation(checkout_id, type, offset) {
            this.loaded.exporting = false

            this.$http.get(`api/checkout/${checkout_id}/export`, {
                params: {
                    offset,
                    export_type: type
                },
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                responseType: 'blob'
            })
                .then(res => {
                    let uri = URL.createObjectURL(res.data)

                    let filename = res.headers['X-Filename'] === null || res.headers['X-Filename'] === undefined ?
                        res.headers['x-filename'] :
                        res.headers['X-Filename']

                    if (filename === undefined || filename === null) {
                        filename = 'reporte.xlsx'
                    }

                    const tag = document.createElement('a')
                    tag.href = uri
                    tag.setAttribute('download', filename)

                    tag.click()
                })
                .catch(error => {
                    console.log(error.response.data)
                })
                .finally(() => {
                    this.loaded.exporting = true
                })
        },
        exportProfit(checkout_id, offset, export_type) {
            this.loaded.exporting = false

            this.$http.get(`api/checkout/${checkout_id}/export-profit`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                params: {
                    offset,
                    export_type
                },
                responseType: 'blob'
            })
                .then(res => {
                    let uri = URL.createObjectURL(res.data)

                    let filename = res.headers['X-Filename'] === null || res.headers['X-Filename'] === undefined ?
                        res.headers['x-filename'] :
                        res.headers['X-Filename']

                    if (filename === undefined || filename === null) {
                        filename = 'reporte.xlsx'
                    }

                    const tag = document.createElement('a')
                    tag.href = uri
                    tag.setAttribute('download', filename)

                    tag.click()
                })
                .catch(error => {
                    console.log(error.response)
                })
                .finally(() => {
                    this.loaded.exporting = true
                })
        },
        exportAccountOperation(account_id, checkout_id, operation_id) {
            this.loaded.exporting = false

            this.$http.get(`api/checkout/account-export/${checkout_id}/${operation_id}/${account_id}`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                responseType: 'blob'
            })
                .then(res => {
                    let uri = URL.createObjectURL(res.data)

                    let filename = res.headers['X-Filename'] === null || res.headers['X-Filename'] === undefined ?
                        res.headers['x-filename'] :
                        res.headers['X-Filename']

                    if (filename === undefined || filename === null) {
                        filename = 'reporte.xlsx'
                    }

                    const tag = document.createElement('a')
                    tag.href = uri
                    tag.setAttribute('download', filename)

                    tag.click()
                })
                .catch(error => {
                    console.log(error.response.data)
                })
                .finally(() => {
                    this.loaded.exporting = true
                })
        },
        exportAllAccounts(operation_id, checkout_id) {
            this.loaded.exporting = false

            this.$http.get(`api/checkout/all-accounts/${checkout_id}/${operation_id}`, {
                headers: {
                    'Authorization': `Bearer ${this.$root.auth.token}`
                },
                responseType: 'blob'
            })
                .then(res => {
                    let uri = URL.createObjectURL(res.data)

                    let filename = res.headers['X-Filename'] === null || res.headers['X-Filename'] === undefined ?
                        res.headers['x-filename'] :
                        res.headers['X-Filename']

                    if (filename === undefined || filename === null) {
                        filename = 'reporte.xlsx'
                    }

                    const tag = document.createElement('a')
                    tag.href = uri
                    tag.setAttribute('download', filename)

                    tag.click()
                })
                .catch(error => {
                    console.log(error.response.data)
                })
                .finally(() => {
                    this.loaded.exporting = true
                })
        }
    },
    name: "MainView"
}
</script>

<style>
.vc-container .vc-day {
    margin-bottom: 1rem !important;
}
</style>
