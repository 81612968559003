<template>
    <div>
        <b-button
            variant="is-warning"
            type="is-warning"
            rounded
            :loading="loading"
            size="is-small"
            @click="showModal=true">
            Errores
            <span
                v-if="transaction['active_errors'].length"
            >
                ({{transaction['active_errors'].length}})
            </span>
        </b-button>

        <b-modal
            v-model="showModal"
            :width="640"
        >
            <div
                class="py-2 px-2"
            >
                <div class="box ">
                    <h3 class="subtitle">Enviar notificación de error al cliente</h3>

                    <b-field label="Motivo">
                        <b-select
                            placeholder="Especifique el motivo"
                            v-model="motive"
                        >
                            <option
                                v-for="option in standard_motives"
                                :value="option"
                                :key="option.id">
                                {{ option.text }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field is-horizontal label="Texto a incluir">
                        <b-input
                            v-model="text"
                            type="textarea"></b-input>
                    </b-field>
                    <div class="is-flex is-justify-content-center">
                        <b-button
                            @click="submit"
                            :loading="sending"
                            :disabled="sending"
                            native-type="button"
                            type="is-success"
                            rounded>
                            Guardar
                        </b-button>
                    </div>
                </div>
                <div>
                    <h3 class="">
                        Errores reportados
                    </h3>
                    <div
                        v-for="error in transaction.active_errors"
                        :key="error.id"
                        class="box d-flex justify-content-between ">
                        <div>
                            <h2 class="subtitle mb-3 has-text-link-dark is-clickeable">
                                {{error.motive}}
                            </h2>
                            <div>
                                Creado: {{ $moment(error.created_at).format('DD MMMM [de] YYYY hh:mm a') }}
                            </div>
                            <div
                                v-if="error.resolved_at !== '0000-00-00 00:00:00'"
                            >
                                Resuelto: {{error.resolved_at}}
                            </div>
                        </div>
                        <b-button
                            v-if="error.resolved_at === '0000-00-00 00:00:00'"
                            @click="markAsResolved(error)"
                            class="ml-2"
                            :loading="sending"
                            :disabled="sending"
                            native-type="button"
                            type="is-success"
                            rounded>
                            Marcar como resuelto
                        </b-button>
                        <b-tag
                            v-else
                            type="is-primary">
                            Resuelto
                        </b-tag>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    name: 'TransactionErrorModal',
    props: {
        transaction: {
            type: Object,
        },
    },
    data() {
        return {
            showModal: false,
            standard_motives: [{
                text: 'Error en cuenta en Euros',
            }, {
                text: 'Error en cuenta en Bs.'
            }],
            motive: {},
            text: '',
            sending: false,
            loading: false
        }
    },
    methods: {
        markAsResolved(error) {
            this.sending = true;
            this.$http(`api/admin/error/${error.id}/mark-as-resolved`,{
                method: 'post',
                headers: {
                    'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
                },
            }).then(({data}) => {
                error.resolved_at = data.error.resolved_at;
            }).finally(() => {
                this.sending = false;
            })
            ;
        },
        submit() {
            this.sending = true;
            let motive = `${this.motive.text}: ${this.text}`;
            this.$http(`api/admin/transaction/${this.transaction.id}/error`,{
                method: 'post',
                headers: {
                    'Authorization': this.$root.auth.type + ' ' + this.$root.auth.token
                },
                data: {
                    'motive': motive,
                }
            }).then(({data}) => {
                this.transaction.active_errors = data.active_errors;
                this.motive = '';
            }).finally(() => {
                this.sending = false;
            })
            ;
        },
    }
}
</script>